import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectOurDataHelpsPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { Link } from '../../components/Link';
import { List, ListItem, ListItemText } from '../../components/List';
import { SocialLink } from '../../components/SocialLink';

type ClassKey = 'main';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
}));

export type TProjectOurDataHelpsP = {
    data: GQLProjectOurDataHelpsPageQuery;
};

function ProjectOurDataHelps(props: TReactFCP<TProjectOurDataHelpsP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml }: GQLProjectOurDataHelpsPageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectOurDataHelpsPageQuery['projectsYaml']> = projectsYaml!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                <Link variant='external' to='https://ourdatahelps.org/'>OurDataHelps</Link> was one of Qntfy's first
                major products built to quantatively study mental health. It's premise is to descern unique
                natural language communication patterns representative of mental health conditions by analyzing
                social media data. Qntfy has conducted its own study to understand, predict, and prevent deaths by
                suicide since the release of OurDataHelps in 2016.
            </Typography>
            <Typography paragraph>
                Each study can be made available for public participation or limited to a set of participants which the
                practitioner of the study controls. All data is volunteerly provided by participants who can decide how
                much or little they'd like to provide. Data for each study is collected, processed, and stored securely
                and when accessed, it is deidentified for protection of the participants and success of the study.
            </Typography>
            <Typography paragraph>
                To date, OurDataHelps has been used to study
            </Typography>
            <List paragraph>
                <ListItem>
                    <ListItemText primary={
                        <React.Fragment>
                            <Link variant='external' to='https://ourdatahelps.org'>Suicide</Link> by{` `}
                            <Link variant='external' to='https://qntfy.com'>Qntfy</Link>
                        </React.Fragment>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            <Link variant='external' to='https://uw.ourdatahelps.org'>
                                Suicide and self-injury in adolescents
                            </Link>
                            {` `}by the{` `}
                            <Link variant='external' to='https://uw.edu/'>University of Washington</Link>
                        </React.Fragment>
                    )} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            <Link variant='external' to='https://warriorsconnect.ourdatahelps.org'>
                                Veteran well-being
                            </Link>
                            {` `} by the{` `}
                            <Link variant='external' to='https://www.bushcenter.org/wwa'>
                                Warrior Wellness Alliance
                            </Link>
                        </React.Fragment>
                    )} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            <Link variant='external' to='https://umd.ourdatahelps.org'>Schizophrenia</Link> by
                            the <Link variant='external' to='https://umaryland.edu'>University of Maryland</Link>
                        </React.Fragment>
                    )} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            <Link variant='external' to='https://ocs.ourdatahelps.org'>Opiod abuse and addiction</Link>
                            {` `}by the{` `}
                            <Link variant='external' to='http://www.opioidcenterofsupport.org/'>
                                Opioid Center of Support
                            </Link>
                            {` and `}
                            <Link variant='external' to='https://jhu.edu'>Johns Hopkins University</Link>
                        </React.Fragment>
                    )} />
                </ListItem>
            </List>
            <Typography paragraph>
                OurDataHelps owes much of its success to its original creator,{` `}
                <SocialLink variant='github' handle='jmsdnns' disableBrandColor disableIcon enableUnderline>
                    James Dennis
                </SocialLink>
                . Without his efforts it would have been much more difficult to expand the project to as many studies
                as it did. After Qntfy's study was well underway, James moved on to other projects and I took over his
                role as project lead and developer.
            </Typography>
            <Typography paragraph>
                I was responsible for building, deploying, and managing every custom deployment of OurDataHelps. As the
                subject matter expert of the product I was the technical pre-sales consultant, built proof
                of concept integrations and customizations for customers, was the primary point of contact for techincal
                and other matters for the duration of each study, and ensured all data was delivered securely to each
                customer regularly.
            </Typography>
            <Typography paragraph>
                From a technical perspective, the most challenging aspects of this process were the design and data
                requirements. While I have a <strong>strong</strong> interest in frontend, design, and UI/UX, at that
                time I had not crafted a "pixel-perfect" design to match a customer's specifications and/or their
                corporate brand before. You can probably see my skills progress{` `}
                <Link variant='external' to='https://umd.ourdatahelps.org'>across</Link>{` `}
                <Link variant='external' to='https://ocs.ourdatahelps.org'>each</Link>{` `}
                <Link variant='external' to='https://uw.ourdatahelps.org'>successive</Link>{` `}
                <Link variant='external' to='https://warriorsconnect.ourdatahelps.org'>deployment</Link>.
            </Typography>
            <Typography paragraph>
                With each release came requests for access to data from more social media
                platforms that we did not support. In total I was responsible for building and integrating five and
                managing data flows for all eight social media OAuth-based APIs.
            </Typography>
            <List paragraph>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='twitter' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='facebook' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='instagram' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='fitbit' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='tumblr' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='reddit' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='jawbone' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
                <ListItem>
                    <ListItemText primary={
                        <SocialLink variant='runkeeper' enableText disableColor disableIcon homepage />
                    } />
                </ListItem>
            </List>
            <Typography paragraph>
                As with <Link variant='internal' to='/projects/quinn/'>Quinn</Link>, the backend was was designed as a
                system of microservices. It was optimized to process lots of data quickly, store data securely, and
                handle deidentification of data easily. It's built with GoLang and Python, supported by Kafka, and
                deployed on Mesos/Marathon.
            </Typography>
            <Typography paragraph>
                It was great to be a part of OurDataHelps. I was able to realize full-stack engineering almost in its
                entirety and it helped solidify my deep interest in building products from the ground up.
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectOurDataHelpsP>): TReactFCR => (
    <Page component={ProjectOurDataHelps} cprops={props} />
);

export const query = graphql`
    query ProjectOurDataHelpsPage {
        sitePage(internalComponentName: {eq: "ComponentProjectsOurdatahelps"}) {
            path
        }
        projectsYaml(pid: {eq: "ourdatahelps"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
