import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { ListProps } from '@material-ui/core/List';
import { List as MuiList, ListItem as MuiListItem, ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText } from '@material-ui/core';
import MuiListContext from '@material-ui/core/List/ListContext';
import { ListItemProps } from '@material-ui/core/ListItem';
import { ListItemIconProps } from '@material-ui/core/ListItemIcon';
import { IconProp, TBaseIconProps } from './FAIcons';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { ListItemTextProps } from '@material-ui/core/ListItemText';
import { TTypographyP, Typography } from './Typography';
import { CreateCSSProperties } from '@material-ui/styles';

type StyleProps = { paragraph: boolean; };
type ClassKey = 'list' | 'listItem' | 'icon' | 'text' | 'textInset';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    list: theme.baseline.list,
    listItem(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = {
            ...theme.baseline.listItem,
            padding: 0,
        };
        if (props.paragraph) {
            style.alignItems = 'baseline';
        }
        return style;
    },
    icon(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = { color: theme.palette.custom.text.icon.lighter };
        if (props.paragraph) {
            style.display = 'flex';
            style.justifyContent = 'center';
            style.width = theme.baseline.list.marginLeft;
            style.marginLeft = `-${theme.baseline.list.marginLeft}px`;
        }
        return style;
    },
    text(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = {
            flex: '1 1 auto',
            minWidth: 0,
            marginTop: 0,
            marginBottom: 0,
        };
        if (props.paragraph) {
            style.marginLeft = 0;
        }
        return style;
    },
    textInset: {},
}));

type TListContext = {
    paragraph: boolean;
    icon: React.ReactNode;
};
const ListContext: React.Context<TListContext> = React.createContext<TListContext>({ paragraph: false, icon: null });

/**
 * List
 */

export type TListP<TIconP extends TBaseIconProps> = ListProps & {
    paragraph?: boolean;
    icon?: IconProp<TIconP>;
    ListItemIconProps?: Omit<TListItemIconP, 'paragraph' | 'children'>;
    disableIcon?: boolean;
};

export function List<TIconP extends TBaseIconProps>(props: TReactFCP<TListP<TIconP>>): TReactFCR {
    const {
        className,
        ListItemIconProps,
        icon,
        disableIcon = false,
        paragraph = false,
        disablePadding = paragraph,
        ...muiProps
    }: TListP<TIconP> = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ paragraph });
    const listStyle: string = clsx({ [styles.list]: paragraph }, className);
    const context: TListContext = {
        paragraph,
        icon: disableIcon ? null : icon === undefined ?
            <ListItemIcon {...ListItemIconProps}><KeyboardArrowRight fontSize='small' /></ListItemIcon> :
            <ListItemIcon {...ListItemIconProps}><icon.Component {...icon.props as TIconP} /></ListItemIcon>
    };
    return (
        <ListContext.Provider value={context}>
            <MuiList className={listStyle} disablePadding {...muiProps} />
        </ListContext.Provider>
    );
}

/**
 * Icon
 */

export type TListItemIconP = ListItemIconProps;

export function ListItemIcon(props: TReactFCP<TListItemIconP>): TReactFCR {
    const { className, ...muiProps }: TListItemIconP = props;
    const { paragraph }: TListContext = React.useContext(ListContext);
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ paragraph });
    const iconStyle: string = clsx(styles.icon, className);
    return <MuiListItemIcon className={iconStyle} {...muiProps} />;
}

/**
 * List Item
 */

export type TListItemP<
    TIconP extends TBaseIconProps, C extends React.ElementType = 'li'
    > = ListItemProps<C, { button?: boolean; }> & {
        icon?: IconProp<TIconP>;
        ListItemIconProps?: Omit<TListItemIconP, 'paragraph'>;
    };

export function ListItem<TIconP extends TBaseIconProps, C extends React.ElementType = 'li'>(
    props: TReactFCP<TListItemP<TIconP, C>>
): TReactFCR {
    const {
        className,
        children,
        ListItemIconProps,
        icon,
        button = false,
        ...muiProps
    }: TListItemP<TIconP, C> = props;
    const { paragraph, icon: defaultIcon }: TListContext = React.useContext(ListContext);
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ paragraph });
    const itemStyle: string = clsx({ [styles.listItem]: paragraph }, className);
    const iconNode: React.ReactNode = icon === undefined ? defaultIcon : (
        <ListItemIcon {...ListItemIconProps}><icon.Component {...icon.props as TIconP} /></ListItemIcon>
    );
    return (
        <MuiListItem className={itemStyle} button={button as TListItemP<TIconP, C>['button']} {...muiProps}>
            {iconNode}
            {children}
        </MuiListItem>
    );
}

/**
 * Text
 */

export type TListItemTextP = Omit<ListItemTextProps, 'primaryTypographyProps' | 'secondaryTypographyProps'> & {
    primaryTypographyProps?: TTypographyP;
    secondaryTypographyProps?: TTypographyP;
};

export function ListItemText(props: TReactFCP<TListItemTextP>): TReactFCR {
    const {
        className,
        primary: primaryNode,
        primaryTypographyProps,
        secondary: secondaryNode,
        secondaryTypographyProps,
        inset = false,
        disableTypography = false,
        ...muiProps
    }: TListItemTextP = props;
    const { paragraph }: TListContext = React.useContext(ListContext);
    const { dense = false }: { dense?: boolean; } = React.useContext(MuiListContext);
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ paragraph });
    const textStyle: string = clsx(styles.text, { [styles.textInset]: inset }, className);
    let primary: React.ReactNode = primaryNode;
    let secondary: React.ReactNode = primaryNode;
    if (!disableTypography) {
        if (primary !== undefined) {
            primary = (
                <Typography component='span' variant={dense ? 'body2' : 'body1'} {...primaryTypographyProps}>
                    {primaryNode}
                </Typography>
            );
        }
        if (secondary !== undefined) {
            secondary = (
                <Typography variant='body2' color='light' {...secondaryTypographyProps}>{secondaryNode}</Typography>
            );
        }
    }
    return (
        <MuiListItemText
            className={textStyle}
            inset={inset}
            disableTypography // We use our own typography component instead
            primary={primary}
            secondary={secondary}
            {...muiProps}
        />
    );
}
